<template>
    <div></div>
</template>

<script>


export default {
    name: "Loan",
    components: {

    },
    created(){
        console.log(this.$route.params.id);
    },
    methods: {

    }
};
</script>

<style scoped>

</style>
